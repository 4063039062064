@import "inspinia";

.swal-icon.swal-icon--success {
    margin-top: 30px;
    margin-bottom: 30px;
}

th.sorting a {
	padding-right: 18px;
	position: relative;
	color: inherit;
	display: inline-block;
	&:before,
	&:after {
		border: 4px solid transparent;
		content: "";
		display: block;
		height: 0;
		right: 5px;
		top: 50%;
		position: absolute;
		width: 0;
	}
	&:before {
		border-bottom-color: #666;
		margin-top: -9px;
	}
	&::after {
		border-top-color: #666;
		margin-top: 1px;
	}
}
th.sorting.desc a{
	&:before{
		display: none;
	}
	&:after{
		margin-top: 0;
	}
}
th.sorting.asc a{
	&:after{
		display: none;
	}
	&:before{
		margin-top: -5px;
	}
}

th .form-group select{
	margin-top: 10px;
}

/* iCheck plugin Square skin, green
----------------------------------- */
.icheckbox_square-green,
.iradio_square-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    background: url(./icheck/square/green.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_square-green {
    background-position: 0 0;
}
.icheckbox_square-green.hover {
    background-position: -24px 0;
}
.icheckbox_square-green.checked {
    background-position: -48px 0;
}
.icheckbox_square-green.disabled {
    background-position: -72px 0;
    cursor: default;
}
.icheckbox_square-green.checked.disabled {
    background-position: -96px 0;
}

.iradio_square-green {
    background-position: -120px 0;
}
.iradio_square-green.hover {
    background-position: -144px 0;
}
.iradio_square-green.checked {
    background-position: -168px 0;
}
.iradio_square-green.disabled {
    background-position: -192px 0;
    cursor: default;
}
.iradio_square-green.checked.disabled {
    background-position: -216px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_square-green,
    .iradio_square-green {
        background-image: url(./icheck/square/green@2x.png);
        -webkit-background-size: 240px 24px;
        background-size: 240px 24px;
    }
}

.entity-selector {
	margin-bottom: 15px;
	margin-top: 25px;
}

.entity-checker {
	display: inline-block;
	background-color: white;
	padding: 15px 45px;
	text-align: center;
	margin-right: 8px;

	i {
		padding-right: 10px;
	}
}

.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.entity-filter {
	display: inline-block;
	background-color: white;
	padding: 15px 45px;
	text-align: center;
	margin-right: 8px;
	color: #a1a1a1;
	border: 1px solid transparent;
	outline: none;

	&:hover, &:focus{
		background: transparent;
    	border: 1px solid #a1a1a1;
    	color: #a1a1a1;
    	outline: none;
	}

	&.active{
		background: transparent;
    	border: 1px solid #a1a1a1;
    	color: #a1a1a1;
	}
}

.first-table-head-no-border{
	th{
		border-bottom: 1px solid #fff!important;
	}
}

.navbar-form-custom.with-search{
	width: 270px;
	input{
		width: 200px;
		display: inline-block;
		height: 30px; margin-top: 15px;
		margin-bottom: 15px;
	}
	@media(max-width: 768px){
		display: initial;
	}
	@media(max-width: 430px){
		width: 230px;
		input{
			width: 150px;
		}
	}
}

.overflow-scroll {
	overflow-x: scroll;
}

.middle-box-custom {
	padding-top: 60px;
}

input#top-search[value=""]+button{
	display: none;
}

.custom-padding-bottom-90 {
    @media (max-width: 768px) {
        padding-bottom: 90px;
    }
}

.spots-list {
    margin-left: 1px;
}

.spots-list-item {
    font-size: 14px;
    margin-left: -1px;
}

.invoice-filter {
    div[class^="col"] {
        margin-bottom: 15px;
    }
}

.file-select {
    .select-button {
        padding: 1rem;
        color: white;
        background-color: #2EA169;
        border-radius: .3rem;
        text-align: center;
        font-weight: bold;
    }

    input#documents {
        display: none;
    }
}

.search-button {
    display: inline-block;

    @media (max-width: 1221px){
        margin-left: 15px;
    }
}
